import React from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './header.css'

const Header = (props) => {
  return (
    <header className={`header-header ${props.rootClassName} `}>
      <div className="">
        <div className="header-container1">
          <Script
            html={`<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-RLNJPZLSET"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-RLNJPZLSET');
</script>

<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TTW7WHP4');</script>
<!-- End Google Tag Manager -->

<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TTW7WHP4"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->

<script>
document.addEventListener('DOMContentLoaded', function() {
    var buttons = document.querySelectorAll('.event-contact-us-tg');
    buttons.forEach(function(button) {
        button.addEventListener('click', function() {
            sendGAEvent('tg', 'Contact Us');
        });
    });

    buttons = document.querySelectorAll('.event-catalog-web');
    buttons.forEach(function(button) {
        button.addEventListener('click', function() {
            sendGAEvent('catalog_web', 'Catalog');
        });
    });

    buttons = document.querySelectorAll('.event-email');
    buttons.forEach(function(button) {
        button.addEventListener('click', function() {
            sendGAEvent('email', 'Email Us');
        });
    });

    buttons = document.querySelectorAll('.event-price-list-gdocs');
    buttons.forEach(function(button) {
        button.addEventListener('click', function() {
            sendGAEvent('price-list-gdocs', 'Price List');
        });
    });

    buttons = document.querySelectorAll('.event-instagram');
    buttons.forEach(function(button) {
        button.addEventListener('click', function() {
            sendGAEvent('instagram', 'Instagram');
        });
    });
});

function sendGAEvent(gaEventName, buttonName) {
    var pathname = window.location.pathname;
    var elementClass = event.target.className;
    var pageLocation = '';

    switch (pathname) {
        case '/clients':
            pageLocation = 'Clients';
            break;
        case '/wholesale':
            pageLocation = 'Wholesale';
            break;
    }

    gtag('event', gaEventName, {
        'button_name': buttonName,
        'element_class': elementClass,
        ...pageLocation && { 'page_location': pageLocation }
    });
}
</script>
`}
            className=""
          ></Script>
        </div>
      </div>
      <a
        href="https://l.synergenex.net/go"
        target="_blank"
        rel="noreferrer noopener"
        className="header-link"
      >
        <img
          alt={props.imageAlt}
          src={props.imageSrc}
          className="header-image"
        />
      </a>
    </header>
  )
}

Header.defaultProps = {
  imageSrc: '/img/logo%20-%20cutout.svg',
  imageAlt: 'logo',
  rootClassName: '',
}

Header.propTypes = {
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Header
