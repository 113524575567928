import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './clients.css'

const Clients = (props) => {
  return (
    <div className="clients-container">
      <Helmet>
        <title>Клиентам — Synergenex</title>
        <meta
          name="description"
          content="Откройте мощь Synergenex для достижения спортивных целей: эффективные препараты для улучшения формы и повышения результатов."
        />
        <meta property="og:title" content="Клиентам — Synergenex" />
        <meta
          property="og:description"
          content="Превзойдите свои ожидания с Synergenex! Инновационные препараты для бодибилдинга и фитнеса, созданные для улучшения ваших результатов."
        />
      </Helmet>
      <Header></Header>
      <div className="clients-hero">
        <div className="clients-hero1">
          <div className="clients-container01">
            <h1 className="clients-hero-heading hero-heading heading1">
              <span>Откройте</span>
              <br></br>
              <span>
                нового
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>себя</span>
            </h1>
            <span className="hero-subheading">
              Путь к превосходству начинается здесь. Наши препараты помогут
              раскрыть истинный потенциал человеческого тела.
            </span>
            <div className="clients-btn-group">
              <a
                href="https://t.me/a_steroids"
                target="_blank"
                rel="noreferrer noopener"
                className="clients-hero-button1 event-contact-us-tg button button-primary"
              >
                <svg viewBox="0 0 1024 1024" className="clients-icon">
                  <path d="M679.429 746.857l84-396c7.429-34.857-12.571-48.571-35.429-40l-493.714 190.286c-33.714 13.143-33.143 32-5.714 40.571l126.286 39.429 293.143-184.571c13.714-9.143 26.286-4 16 5.143l-237.143 214.286-9.143 130.286c13.143 0 18.857-5.714 25.714-12.571l61.714-59.429 128 94.286c23.429 13.143 40 6.286 46.286-21.714zM1024 512c0 282.857-229.143 512-512 512s-512-229.143-512-512 229.143-512 512-512 512 229.143 512 512z"></path>
                </svg>
                <span className="clients-text05">Свяжитесь с нами</span>
              </a>
              <a
                href="https://synergenex.org/ru/products"
                id="catalog-web"
                target="_blank"
                rel="noreferrer noopener"
                className="clients-hero-button11 event-catalog-web button button-primary"
              >
                <span className="clients-text06">
                  <span>Каталог →</span>
                  <br></br>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="clients-details">
        <div className="clients-details1">
          <div className="clients-container02">
            <span className="clients-text09 sectionTitle">О Нас</span>
            <h2 className="clients-details-heading heading2">
              Надежное качество Вашего прогресса
            </h2>
            <span className="clients-details-sub-heading">
              <span>
                В Synergenex мы стремимся предоставить продукты, которые усилят
                Вашу спортивную производительность и поддержат достижение ваших
                целей. Наш ассортимент включает в себя широкий выбор препаратов,
                разработанных с учетом потребностей спортсменов всех уровней. Мы
                обеспечиваем надёжное качество и эффективность наших продуктов,
                помогая Вам улучшить результаты и восстановление после
                тренировок.
              </span>
              <br></br>
              <br></br>
              <span>
                Опытные атлеты и тренеры найдут у нас продукты, которые отвечают
                самым строгим стандартам эффективности и безопасности. Откройте
                для себя новые возможности в мире спортивной фармакологии с
                Synergenex — Вашим надежным партнером на пути к спортивным
                достижениям.
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="https://synergenex.org/app/uploads/2023/12/synergenex-social-promo5-square-300x300.jpg"
            className="clients-image"
          />
        </div>
      </div>
      <div className="clients-features">
        <div className="clients-features-container">
          <div className="clients-features1">
            <div className="clients-container03">
              <span className="clients-text14 sectionTitle">
                <span>Препараты</span>
                <br></br>
              </span>
            </div>
            <div className="clients-iteste">
              <div className="clients-container04">
                <a
                  href="https://synergenex.org/ru/product/i-test-e/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="clients-link"
                >
                  <img
                    alt="image"
                    src="https://synergenex.org/app/uploads/2023/01/I.TEST_.E-860x860.png"
                    className="clients-image1"
                  />
                </a>
              </div>
              <div className="clients-container05">
                <div className="clients-container06">
                  <a
                    href="https://synergenex.org/ru/product/i-test-e/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="clients-link01"
                  >
                    <h3 className="product-title">I.TEST.E</h3>
                  </a>
                </div>
                <span className="clients-text18">
                  Качайся, как никогда раньше.
                </span>
                <div className="clients-container07">
                  <span className="clients-text19">
                    <br></br>
                    <span>
                      I.TEST.E, энантат тестостерона, известен своим мощным
                      анаболическим эффектом, обеспечивая спортсменам
                      значительный прирост силы и массы. Этот долгодействующий
                      препарат подходит для пауэрлифтеров и тяжелоатлетов,
                      стремящихся к улучшению силовых показателей с регулярными
                      инъекциями раз в неделю.
                    </span>
                  </span>
                  <a
                    href="https://synergenex.org/ru/product/i-test-e/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="clients-hero-button2 button-secondary button"
                  >
                    Узнайте больше →
                  </a>
                </div>
              </div>
            </div>
            <div className="clients-itestc">
              <div className="clients-container08">
                <div className="clients-container09">
                  <a
                    href="https://synergenex.org/ru/product/i-test-c-testosterone-cypionate/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="clients-link02"
                  >
                    <h3 className="product-title clients-text22">I.TEST.C</h3>
                  </a>
                </div>
                <span className="clients-text23">Стань больше. Быстро.</span>
                <div className="clients-container10">
                  <span className="clients-text24">
                    <br></br>
                    <span>
                      Эффективный и быстродействующий, ципионат тестостерона
                      I.TEST.C значительно ускоряет прирост силы и мышечной
                      массы. Этот препарат подходит для атлетов, нацеленных на
                      быстрый результат, хотя требует внимательного
                      использования и послекурсовой терапии.
                    </span>
                  </span>
                  <a
                    href="https://synergenex.org/ru/product/i-test-c-testosterone-cypionate/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="clients-hero-button21 button-secondary button"
                  >
                    Узнайте больше →
                  </a>
                </div>
              </div>
              <div className="clients-container11">
                <a
                  href="https://synergenex.org/ru/product/i-test-c-testosterone-cypionate/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="clients-link03"
                >
                  <img
                    alt="image"
                    src="https://synergenex.org/app/uploads/2023/01/I.TEST_.C-860x860.png"
                    className="clients-image2"
                  />
                </a>
              </div>
            </div>
            <div className="clients-iteste1">
              <div className="clients-container12">
                <a
                  href="https://synergenex.org/ru/product/i-prop-t-testosterone-propionate/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="clients-link04"
                >
                  <img
                    alt="image"
                    src="https://synergenex.org/app/uploads/2023/01/I.PROP_.T-860x860.png"
                    className="clients-image3"
                  />
                </a>
              </div>
              <div className="clients-container13">
                <div className="clients-container14">
                  <a
                    href="https://synergenex.org/ru/product/i-prop-t-testosterone-propionate/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="clients-link05"
                  >
                    <h3 className="product-title clients-text27">I.PROP.T</h3>
                  </a>
                </div>
                <span className="clients-text28">Золотой стандарт силы.</span>
                <div className="clients-container15">
                  <span className="clients-text29">
                    Эффективный препарат тестостерона пропионата I.PROP.T для
                    ускоренного роста мышц и повышения выносливости.
                    Краткосрочное, но мощное действие для серьезных результатов.
                  </span>
                  <a
                    href="https://synergenex.org/ru/product/i-prop-t-testosterone-propionate/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="clients-hero-button22 button-secondary button"
                  >
                    Узнайте больше →
                  </a>
                </div>
              </div>
            </div>
            <div className="clients-itestc1">
              <div className="clients-container16">
                <div className="clients-container17">
                  <a
                    href="https://synergenex.org/ru/product/i-sust-sustanon-blend-of-testosterone/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="clients-link06"
                  >
                    <h3 className="product-title clients-text30">I.SUST</h3>
                  </a>
                </div>
                <span className="clients-text31">Весь спектр мощности.</span>
                <div className="clients-container18">
                  <span className="clients-text32">
                    <br></br>
                    <span>
                      I.SUST (сустанон) сочетает в себе четыре вида тестостерона
                      для непрерывного и многоступенчатого эффекта.
                      Сбалансированная смесь для стабильного и длительного
                      увеличения уровня тестостерона в крови, обеспечивая
                      эффективный рост мышц и силы.
                    </span>
                  </span>
                  <a
                    href="https://synergenex.org/ru/product/i-sust-sustanon-blend-of-testosterone/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="clients-hero-button23 button-secondary button"
                  >
                    Узнайте больше →
                  </a>
                </div>
              </div>
              <div className="clients-container19">
                <a
                  href="https://synergenex.org/ru/product/i-sust-sustanon-blend-of-testosterone/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="clients-link07"
                >
                  <img
                    alt="image"
                    src="https://synergenex.org/app/uploads/2023/01/I.SUST_-860x860.png"
                    className="clients-image4"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="clients-features2">
        <div className="clients-container20">
          <div className="clients-container21">
            <span className="clients-text35 sectionTitle">
              <span>Почему мы</span>
              <br></br>
            </span>
            <h2 className="clients-features-heading heading2">
              <span>
                Драйвер спортивного совершенства
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
            </h2>
          </div>
          <div className="clients-container22">
            <div className="clients-feature-card">
              <svg
                viewBox="0 0 950.8571428571428 1024"
                className="clients-icon02"
              >
                <path d="M261.714 504.571c-24-52.571-42.286-122.286-42.286-212h-146.286v54.857c0 56 76 133.714 188.571 157.143zM877.714 347.429v-54.857h-146.286c0 89.714-18.286 159.429-42.286 212 112.571-23.429 188.571-101.143 188.571-157.143zM950.857 274.286v73.143c0 108.571-131.429 228.571-309.714 237.143-22.857 29.143-44 46.286-54.286 54.286-30.286 27.429-38.286 56-38.286 92.571s18.286 73.143 73.143 73.143 109.714 36.571 109.714 91.429v36.571c0 10.286-8 18.286-18.286 18.286h-475.429c-10.286 0-18.286-8-18.286-18.286v-36.571c0-54.857 54.857-91.429 109.714-91.429s73.143-36.571 73.143-73.143-8-65.143-38.286-92.571c-10.286-8-31.429-25.143-54.286-54.286-178.286-8.571-309.714-128.571-309.714-237.143v-73.143c0-30.286 24.571-54.857 54.857-54.857h164.571v-54.857c0-50.286 41.143-91.429 91.429-91.429h329.143c50.286 0 91.429 41.143 91.429 91.429v54.857h164.571c30.286 0 54.857 24.571 54.857 54.857z"></path>
              </svg>
              <div className="clients-container23">
                <h3 className="clients-text40 heading3">
                  Максимальная эффективность
                </h3>
                <span className="clients-text41">
                  <span>
                    Наши продукты спроектированы для оптимизации тренировочного
                    процесса, помогая быстро достигать максимальных результатов.
                  </span>
                  <br></br>
                </span>
              </div>
            </div>
            <div className="clients-feature-card1">
              <svg viewBox="0 0 1024 1024" className="clients-icon04">
                <path d="M512 950.857c-9.143 0-18.286-3.429-25.143-10.286l-356.571-344c-4.571-4-130.286-118.857-130.286-256 0-167.429 102.286-267.429 273.143-267.429 100 0 193.714 78.857 238.857 123.429 45.143-44.571 138.857-123.429 238.857-123.429 170.857 0 273.143 100 273.143 267.429 0 137.143-125.714 252-130.857 257.143l-356 342.857c-6.857 6.857-16 10.286-25.143 10.286z"></path>
              </svg>
              <div className="clients-container24">
                <h3 className="clients-text44 heading3">
                  Индивидуальный подход
                </h3>
                <span className="clients-text45">
                  Широкий ассортимент препаратов позволяет подобрать идеальные
                  решения для ваших уникальных тренировочных потребностей и
                  целей.
                </span>
              </div>
            </div>
            <div className="clients-feature-card2">
              <svg viewBox="0 0 1024 1024" className="clients-icon06">
                <path d="M542.165 84.608c-16.683-16.683-43.733-16.64-60.373 0.043l-241.195 241.621c-74.965 75.008-112.427 173.355-112.384 271.573s37.547 196.565 112.555 271.488 173.355 112.427 271.573 112.384 196.565-37.547 271.488-112.555 112.427-173.355 112.384-271.573-37.547-196.565-112.555-271.488zM512.043 175.147l211.285 211.285c58.368 58.325 87.552 134.656 87.552 211.157s-29.099 152.875-87.424 211.2-134.656 87.509-211.157 87.552-152.875-29.099-211.2-87.424-87.509-134.656-87.552-211.157 29.099-152.875 87.424-211.2z"></path>
              </svg>
              <div className="clients-container25">
                <h3 className="clients-text46 heading3">
                  Безопасность и качество
                </h3>
                <span className="clients-text47">
                  Наши продукты проходят тщательный этап фильтрации, обеспечивая
                  безопасность и надежность для Вашего здоровья и спортивных
                  достижений.
                </span>
              </div>
            </div>
            <div className="clients-feature-card3">
              <svg
                viewBox="0 0 914.2857142857142 1024"
                className="clients-icon08"
              >
                <path d="M146.286 768c0-20-16.571-36.571-36.571-36.571-20.571 0-36.571 16.571-36.571 36.571 0 20.571 16 36.571 36.571 36.571 20 0 36.571-16 36.571-36.571zM237.714 475.429v365.714c0 20-16.571 36.571-36.571 36.571h-164.571c-20 0-36.571-16.571-36.571-36.571v-365.714c0-20 16.571-36.571 36.571-36.571h164.571c20 0 36.571 16.571 36.571 36.571zM914.286 475.429c0 30.286-12 62.857-31.429 85.143 6.286 18.286 8.571 35.429 8.571 43.429 1.143 28.571-7.429 55.429-24.571 78.286 6.286 21.143 6.286 44 0 66.857-5.714 21.143-16.571 40-30.857 53.714 3.429 42.857-6.286 77.714-28 103.429-24.571 29.143-62.286 44-112.571 44.571h-73.714c-81.714 0-158.857-26.857-220.571-48-36-12.571-70.286-24.571-90.286-25.143-19.429-0.571-36.571-16.571-36.571-36.571v-366.286c0-18.857 16-34.857 34.857-36.571 21.143-1.714 76-69.714 101.143-102.857 20.571-26.286 40-50.857 57.714-68.571 22.286-22.286 28.571-56.571 35.429-89.714 6.286-33.714 13.143-69.143 37.714-93.143 6.857-6.857 16-10.857 25.714-10.857 128 0 128 102.286 128 146.286 0 46.857-16.571 80-32 109.714-6.286 12.571-12 18.286-16.571 36.571h158.286c59.429 0 109.714 50.286 109.714 109.714z"></path>
              </svg>
              <div className="clients-container26">
                <h3 className="clients-text48 heading3">
                  Доверие профессионалов
                </h3>
                <span className="clients-text49">
                  Мы гордимся своими клентами, ведь Synergenex выбирают
                  спортсмены и тренеры благодаря надежности и эффективности.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="clients-hero2">
        <a
          href="https://l.synergenex.net/go"
          target="_blank"
          rel="noreferrer noopener"
          className="clients-link08"
        >
          <img
            alt="logo"
            src="/img/logo%20-%20cutout.svg"
            className="clients-image5"
          />
        </a>
        <div className="clients-container27">
          <span className="hero-subheading">
            Ваш первый шаг к успеху — выбор правильных продуктов. Откройте для
            себя, как наши препараты могут помочь вам воплотить Ваши цели в
            реальность.
          </span>
          <div className="clients-btn-group1">
            <a
              href="https://synergenex.org/ru/products"
              target="_blank"
              rel="noreferrer noopener"
              className="clients-link09 event-catalog-web button"
            >
              <span className="clients-text50">
                <span className="clients-text51">Каталог →</span>
                <br></br>
              </span>
            </a>
            <a
              href="https://t.me/a_steroids"
              target="_blank"
              rel="noreferrer noopener"
              className="clients-link10 event-contact-us-tg button"
            >
              <span className="clients-text53">
                <span className="clients-text54">Напишите нам</span>
                <br></br>
              </span>
            </a>
          </div>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name1"></Footer>
    </div>
  )
}

export default Clients
