import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './partners.css'

const Partners = (props) => {
  return (
    <div className="partners-container">
      <Helmet>
        <title>Partners - Synergenex</title>
        <meta
          name="description"
          content="Присоединяйтесь к программе оптовых партнеров Synergenex: конкурентные цены, высокое качество продуктов и поддержка бизнеса."
        />
        <meta property="og:title" content="Партнёрам — Synergenex" />
        <meta
          property="og:description"
          content="Развивайте свой бизнес с Synergenex: привлекательные условия для оптовиков и дропшипперов. Качественные продукты, выгодные цены и поддержка на каждом этапе!"
        />
      </Helmet>
      <Header rootClassName="header-root-class-name1"></Header>
      <div className="partners-hero">
        <div className="partners-hero1">
          <div className="partners-container01">
            <h1 className="partners-hero-heading hero-heading heading1">
              <span>Откройте</span>
              <br></br>
              <span>
                нового
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>себя</span>
            </h1>
            <span className="hero-subheading">
              Путь к превосходству начинается здесь. Наши препараты помогут
              раскрыть истинный потенциал человеческого тела.
            </span>
            <div className="partners-btn-group">
              <a
                href="https://t.me/synergenex"
                target="_blank"
                rel="noreferrer noopener"
                className="partners-hero-button1 event-contact-us-tg button button-primary"
              >
                Свяжитесь с нами
              </a>
              <a
                href="https://l.synergenex.net/wholesale-1-RU"
                target="_blank"
                rel="noreferrer noopener"
                className="partners-hero-button2 button-secondary event-price-list-gdocs button"
              >
                Прайс-лист →
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="partners-details">
        <div className="partners-details1">
          <div className="partners-container02">
            <span className="partners-text05 sectionTitle">
              Опт и дропшиппинг
            </span>
            <h2 className="partners-details-heading heading2">
              Расширяем возможности Вашего бизнеса
            </h2>
            <span className="partners-details-sub-heading">
              <span>
                В Synergenex мы предлагаем гибкие и выгодные условия для оптовых
                покупателей и партнеров по дропшиппингу.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <br></br>
              <span>
                Оптовым клиентам мы гарантируем конкурентоспособные цены и
                обширный ассортимент, обеспечивающий высокий спрос и
                удовлетворение потребностей ваших клиентов. Для дропшипперов —
                удобство и простоту работы с нами, включая быструю доставку и
                оперативную поддержку.
              </span>
              <br></br>
              <br></br>
              <span>
                Откройте новые горизонты в мире спортивной фармакологии для
                Вашего бизнеса с Synergenex!
              </span>
            </span>
          </div>
          <img
            src="/img/synergenex-social-promo2-nologo-1400w.jpg"
            loading="lazy"
            className="partners-details-image"
          />
        </div>
      </div>
      <div id="features" className="partners-features">
        <div className="partners-features-container">
          <div className="partners-features1">
            <div className="partners-container03">
              <span className="partners-text13 sectionTitle">
                <span>Почему мы</span>
                <br></br>
              </span>
              <h2 className="partners-features-heading heading2">
                <span className="partners-text16">Преимущества для Вас</span>
                <br></br>
              </h2>
            </div>
            <div className="partners-container04">
              <div className="partners-feature-card">
                <svg viewBox="0 0 1024 1024" className="partners-icon">
                  <path d="M542.165 84.608c-16.683-16.683-43.733-16.64-60.373 0.043l-241.195 241.621c-74.965 75.008-112.427 173.355-112.384 271.573s37.547 196.565 112.555 271.488 173.355 112.427 271.573 112.384 196.565-37.547 271.488-112.555 112.427-173.355 112.384-271.573-37.547-196.565-112.555-271.488zM512.043 175.147l211.285 211.285c58.368 58.325 87.552 134.656 87.552 211.157s-29.099 152.875-87.424 211.2-134.656 87.509-211.157 87.552-152.875-29.099-211.2-87.424-87.509-134.656-87.552-211.157 29.099-152.875 87.424-211.2z"></path>
                </svg>
                <div className="partners-container05">
                  <h3 className="partners-text18 heading3">
                    Надёжное, стабильное качество
                  </h3>
                  <span className="partners-text19">
                    <span>
                      Мы гордимся постоянством и надёжностью своих продуктов.
                      Наши спортстмены и атлеты знают, что каждый продукт
                      эффективен и работает на 100%.
                    </span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="partners-feature-card1">
                <svg viewBox="0 0 1024 1024" className="partners-icon2">
                  <path d="M480 64c-265.096 0-480 214.904-480 480 0 265.098 214.904 480 480 480 265.098 0 480-214.902 480-480 0-265.096-214.902-480-480-480zM480 928c-212.078 0-384-171.922-384-384s171.922-384 384-384c212.078 0 384 171.922 384 384s-171.922 384-384 384zM512 512v-128h128v-64h-128v-64h-64v64h-128v256h128v128h-128v64h128v64h64v-64h128.002l-0.002-256h-128zM448 512h-64v-128h64v128zM576.002 704h-64.002v-128h64.002v128z"></path>
                </svg>
                <div className="partners-container06">
                  <h3 className="partners-heading heading3">
                    Кон­куретно­­спо­соб­ные и гибкие цены
                  </h3>
                  <span className="partners-text22">
                    Мы предлагаем конкурентоспособные оптовые цены и
                    привлекательные условия для дропшипперов, делая
                    сотрудничество с нами выгодным и удобным.
                  </span>
                </div>
              </div>
              <div className="partners-feature-card2">
                <svg viewBox="0 0 1024 1024" className="partners-icon4">
                  <path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM320 512c0-106.040 85.96-192 192-192s192 85.96 192 192-85.96 192-192 192-192-85.96-192-192zM925.98 683.476v0l-177.42-73.49c12.518-30.184 19.44-63.276 19.44-97.986s-6.922-67.802-19.44-97.986l177.42-73.49c21.908 52.822 34.020 110.73 34.020 171.476s-12.114 118.654-34.020 171.476v0zM683.478 98.020v0 0l-73.49 177.42c-30.184-12.518-63.276-19.44-97.988-19.44s-67.802 6.922-97.986 19.44l-73.49-177.422c52.822-21.904 110.732-34.018 171.476-34.018 60.746 0 118.654 12.114 171.478 34.020zM98.020 340.524l177.422 73.49c-12.518 30.184-19.442 63.276-19.442 97.986s6.922 67.802 19.44 97.986l-177.42 73.49c-21.906-52.822-34.020-110.73-34.020-171.476s12.114-118.654 34.020-171.476zM340.524 925.98l73.49-177.42c30.184 12.518 63.276 19.44 97.986 19.44s67.802-6.922 97.986-19.44l73.49 177.42c-52.822 21.904-110.73 34.020-171.476 34.020-60.744 0-118.654-12.114-171.476-34.020z"></path>
                </svg>
                <div className="partners-container07">
                  <h3 className="partners-text23 heading3">
                    Поддержка партнеров
                  </h3>
                  <span className="partners-text24">
                    Наши оптовые покупатели и дропшипперы получают всестороннюю
                    поддержку, включая информацию о продукции и консультации.
                  </span>
                </div>
              </div>
              <div className="partners-feature-card3">
                <svg viewBox="0 0 1024 1024" className="partners-icon6">
                  <path d="M736 652q32-32 32-76t-31-75-75-31q-46 0-76 30l-32 32-30-32q-30-30-76-30-44 0-75 31t-31 75q0 46 30 76l182 182zM234 298q26 0 45-19t19-45-19-45-45-19-45 19-19 45 19 45 45 19zM914 494q24 24 24 60t-24 60l-300 300q-24 24-60 24t-60-24l-384-384q-24-24-24-60v-300q0-34 25-59t59-25h300q36 0 60 24z"></path>
                </svg>
                <div className="partners-container08">
                  <h3 className="partners-text25 heading3">
                    Лояль­ность и возна­гражде­ния
                  </h3>
                  <span className="partners-text26">
                    Наша программа кэшбэка предусматривает бонусы и
                    вознаграждения за повышение объёмов продаж и долгосрочное
                    сотрудничество.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="cashback" className="partners-cashback">
        <div className="partners-cashback1">
          <div className="partners-container09">
            <span className="partners-text27 sectionTitle">
              <span>Кэшбэк</span>
              <br></br>
            </span>
            <h2 className="partners-pricing-heading heading2">
              Ваш план вознаграждений
            </h2>
            <span className="partners-pricing-sub-heading">
              <span>
                Увеличьте Ваш доход с каждым заказом. Чем больше продаж, тем
                выше Ваше вознаграждение! 
              </span>
              <br></br>
            </span>
          </div>
          <div className="partners-container10">
            <div className="partners-pricing-card">
              <span className="partners-text32">Для оборота*</span>
              <div className="partners-container11">
                <span className="partners-text33 heading3">От 500 000₽</span>
                <span className="partners-text34">в месяц Вы получаете</span>
              </div>
              <div className="partners-container12">
                <span className="partners-free-plan-price">5</span>
                <span className="partners-text35">% кэшбэка</span>
              </div>
            </div>
            <div className="partners-pricing-card1">
              <span className="partners-text36">Для оборота*</span>
              <div className="partners-container13">
                <span className="partners-text37 heading3">От 1 млн. ₽</span>
                <span className="partners-text38">в месяц Вы получаете</span>
              </div>
              <div className="partners-container14">
                <span className="partners-free-plan-price1">10</span>
                <span className="partners-text39">% кэшбэка</span>
              </div>
            </div>
            <div className="partners-pricing-card2">
              <span className="partners-text40">Для оборота*</span>
              <div className="partners-container15">
                <span className="partners-text41 heading3">От 2,5 МЛН. ₽</span>
                <span className="partners-text42">в месяц Вы получаете</span>
              </div>
              <div className="partners-container16">
                <span className="partners-free-plan-price2">15</span>
                <span className="partners-text43">% кэшбэка</span>
              </div>
            </div>
          </div>
          <div className="partners-pricing-fine-details">
            <p className="partners-pricing-fine-details1">
              <span>
                Период учёта оборота — календарный месяц. Кэшбэк начисляется в
                начале периода, следующего за отчётным.
              </span>
              <br></br>
            </p>
            <p className="partners-pricing-fine-details2">
              * В оборот входит сумма всех заказов, переданных в период учёта,
              за исключением стоимости расходов на доставку.
            </p>
          </div>
        </div>
      </div>
      <div className="partners-hero2">
        <a
          href="https://l.synergenex.net/go"
          target="_blank"
          rel="noreferrer noopener"
          className="partners-link"
        >
          <img
            alt="logo"
            src="/img/logo%20-%20cutout.svg"
            className="partners-image"
          />
        </a>
        <div className="partners-container17">
          <span className="hero-subheading">
            Ваш первый шаг к успеху — выбор правильных продуктов. Позвольте
            нашим препаратам стать ключом к раскрытию истинного потенциала Ваших
            клиентов.
          </span>
          <div className="partners-btn-group1">
            <a
              href="https://l.synergenex.net/wholesale-1-RU"
              target="_blank"
              rel="noreferrer noopener"
              className="partners-link1 event-price-list-gdocs button"
            >
              <span className="partners-text46">
                <span className="partners-text47">Прайс-лист →</span>
                <br></br>
              </span>
            </a>
            <a
              href="https://t.me/synergenex"
              target="_blank"
              rel="noreferrer noopener"
              className="partners-link2 event-contact-us-tg button"
            >
              <span className="partners-text49">
                <span className="partners-text50">Напишите нам</span>
                <br></br>
              </span>
            </a>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Partners
