import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Synergenex</title>
        <meta
          name="description"
          content="Synergenex: превосходные продукты для спортсменов, тренеров и фитнес-любителей. Улучшение формы, набор массы и эффективное восстановление!"
        />
        <meta property="og:title" content="Synergenex" />
        <meta
          property="og:description"
          content="Synergenex: превосходные продукты для спортсменов, тренеров и фитнес-любителей. Улучшение формы, набор массы и эффективное восстановление!"
        />
      </Helmet>
      <Header rootClassName="header-root-class-name"></Header>
      <div className="home-hero">
        <div className="home-container1">
          <Link to="/clients" className="home-navlink">
            <div className="home-container2 homeStartPic">
              <h1 className="home-text hero-heading">Клиентам</h1>
            </div>
          </Link>
          <Link to="/partners" className="home-navlink1">
            <div className="home-container3 homeStartPic">
              <h1 className="home-text1 hero-heading">Оптовикам</h1>
            </div>
          </Link>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  )
}

export default Home
